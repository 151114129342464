import React from 'react';

//Web2 patch notes (aka Steam, no blockchain notes)
export default function PatchNotesWeb2() {
  return (
    <>
      <h1>Phantom Galaxies Version 5.6.2</h1>
      <p>Released 26/09/2024</p>
      <h2>Player Experience</h2>
      <ul>
        <li>Halberd renovations are completed! Explore the brand new Command Center, Hangar and Pilot lounge in a refreshed and modern Halberd space station. We've also visually updated the terminals making each one easily identifiable.</li>
        <li>Level cap updates - Avatar and Mech levels have been increased to level 25. Which also means the maximum power level of gear and weaponry has been updated to 125. Purchase and use XP Boost consumables to level up and get more power quicker!</li>
      </ul>
      <h2>General Gameplay & Balancing</h2>
      <ul>
        <li>Updated Enemy AI - Xanorra Shredder: The Xanorra Shredder has undergone a facelift as well as some behaviour changes to make them more dangerous and in your face! Beware the Shredder, because if you aren't careful they'll shred your starfighter to pieces!</li>
      </ul>
    </>
  );
}
